<template>
  <v-row class="py-12">
    <v-col cols="12">
      <v-row class="d-flex flex-row justify-space-between align-center">
        <h3 class="px-3" v-text="$t('project.form.bodyTitle')" />
        <v-btn
          v-show="isNotFirstPage"
          color="primary"
          outlined
          @click.stop="$confirm($t('project.form.confirmRemovePage')).then(confirm => confirm && $emit('removePage', currentPage))"
        >
          <v-icon left>
            mdi-delete-outline
          </v-icon>
          {{ $t('project.form.removePage', { currentPage }) }}
        </v-btn>
      </v-row>
      <v-row class="my-12 px-3" style="display: block !important;">
        <v-icon
          color="primary"
          size="24"
        >
          lightbulb_outline
        </v-icon>
        <span v-html="$t('project.form.formDisclaimer')" />
      </v-row>
      <v-row>
        <v-col>
          <project-dynamic-form-field
            v-for="(field, i) in fields"
            :id="field.id"
            :key="field.id"
            :current-component="i + 1"
            :field="field"
            :is-last-field="i + 1 === fields.length"
            :is-only-field="fields.length === 1"
            :is-published="isPublished"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProjectDynamicFormBody',
  components: {
    ProjectDynamicFormField: () => import('@/modules/dynamicForm/formFieldsComponents/DynamicFormFieldComponent'),
  },
  props: {
    pages: {
      type: Number,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    fields: {
      type: Array,
      default: null,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isNotFirstPage({ currentPage }) {
      return currentPage !== 1
    },
  },
}
</script>
